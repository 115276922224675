import React from "react";
import Cards from "./Cards";

const Features = () => {
  return (
    <div className="relative w-full mt-20 h-[160vh] md:h-[130vh] bg-gradient-to-r from-pink-400 to-yellow-500 flex justify-center items-center">
      <img
        className="absolute bottom-0 md:-bottom-32 left-0 z-10 opacity-30"
        src="/rb_15769.png"
        alt="Background"
      />
      <div className="absolute px-10 md:px-0 z-20 inset-0 flex justify-center items-start pt-24 bg-black bg-opacity-50">
        <div className="w-full max-w-screen-lg">
          <div className="grid place-items-center pb-10">
            <h1 className="text-4xl font-bold text-white mb-4 text-center">
              Discover the Unique Features of Our Platform
            </h1>
            <p className="text-white text-center">
              Experience a world where strategy, excitement, and fun come together. Explore why our platform is your go-to destination for no-risk fantasy sports.
            </p>
          </div>
          <div className="w-full h-auto">
            <Cards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
