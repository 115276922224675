import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center relative bg-gradient-to-br from-blue-400 via-indigo-300 to-purple-500">
      <div className="w-full h-screen flex flex-col justify-center items-center text-center">
        <h1
          className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600"
          data-aos="fade-down"
        >
          Step into the World of Fantasy Sports
        </h1>
        <p
          className="text-gray-900 text-lg md:text-xl mt-4 max-w-xl"
          data-aos="fade-up"
        >
          Unleash your inner strategist in a world of thrilling challenges.
          Ready to take on the competition?
        </p>
        <div className="flex justify-center mt-10">
          <button
            onClick={() => window.location.href = "https://wa.link/ggnow"}
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold px-8 py-3 rounded-md shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-lg cursor-pointer"
          >
            Join Now
          </button>
        </div>
      </div>

      {/* New Section - No Real Money Involved */}
      <div className="w-full py-20 bg-gradient-to-r from-indigo-400 to-blue-500">
        <div className="text-center text-white px-4">
          <h2
            className="text-3xl md:text-4xl font-semibold mb-6"
            data-aos="fade-up"
          >
            Play for Fun, No Real Money Involved!
          </h2>
          <p className="text-lg mb-8" data-aos="fade-up">
            Enjoy the excitement of fantasy sports without any financial risk!
            Play for fun, compete with friends, and improve your skills.
          </p>
          <div className="flex justify-center gap-6">
            <button
              onClick={() => window.location.href = "https://wa.link/ggnow"}
              className="bg-gradient-to-r cursor-pointer from-blue-600 to-purple-600 text-white font-semibold px-8 py-3 rounded-md shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
            >
              Visit Our Website
            </button>
            <button
              onClick={() => window.location.href = "https://wa.link/ggnow"}
              className="bg-gradient-to-r from-pink-500 to-yellow-400 text-white font-semibold px-8 py-3 rounded-md shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-lg cursor-pointer"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
