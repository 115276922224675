import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full h-auto p-6 md:p-10 bg-blue-700 text-white">
      <div className="grid gap-6 md:grid-cols-2">
        <div>
          <h1 className="text-2xl font-semibold uppercase">Important Links</h1>
          <ul className="space-y-2 text-sm md:text-base px-10 pt-10">
            <Link to="/cookiespolicy" className="hover:underline">
              Cookies Policy
            </Link>
            <br />
            <Link to="/fairplaypolicy" className="hover:underline">
              Fair Play Policy
            </Link>
            <br />
            <Link to="/refundpolicy" className="hover:underline">
              Refund Policy
            </Link>
            <br />
            <Link to="/termsconditions" className="hover:underline">
              Terms & Conditions
            </Link>
            <br />
            <Link to="/privacypolicy" className="hover:underline">
              Privacy Policy
            </Link>
            <br />
          </ul>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <h1 className="text-xl md:text-3xl pt-2 md:pt-4">Stay Safe and Have Fun</h1>
          </div>
          <p className="mt-4 text-sm md:text-base leading-relaxed">
            We are committed to providing a safe and enjoyable experience for all players. Our platform is designed for entertainment, where no real money is involved. Play for fun, challenge your skills, and enjoy the excitement of fantasy sports. We encourage responsible gaming and remind you to always play within your limits.
          </p>
        </div>
      </div>
      <p className="text-center pt-7">© 2024 CricketHeros. All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
