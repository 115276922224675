import React from "react";

const NoRealMoneySection = () => {
  return (
    <div className="relative w-full py-16 md:py-20 bg-gradient-to-r from-yellow-400 to-pink-500 flex justify-center items-center">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-30 z-10"></div>
      <div className="relative z-20 w-full max-w-screen-xl px-6 md:px-20">
        <div className="text-center text-white">
          <h2 className="text-3xl md:text-4xl font-semibold mb-6">
            No Real Money, Just Pure Fun!
          </h2>
          <p className="text-lg md:text-xl text-gray-200 leading-relaxed mb-8">
            Our platform is all about enjoying fantasy sports without the risk. Play games, challenge friends, and win exciting rewards—all without betting real money. It’s about strategy, skill, and fun! Focus on having a good time, not on the stakes.
          </p>
          <a href="https://wa.link/ggnow">
            <button className="bg-gradient-to-r from-yellow-500 to-pink-400 px-10 py-3 rounded-full text-white font-semibold cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-lg">
              Start Playing Now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoRealMoneySection;
