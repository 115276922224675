import React from "react";

const LiveScore = () => {
  // Top fantasy players data without images
  const players = [
    {
      id: 1,
      name: "Virat Kohli",
      role: "Batsman",
      stats: "Runs: 12000+ | Average: 52.04",
    },
    {
      id: 2,
      name: "Rohit Sharma",
      role: "Batsman",
      stats: "Runs: 9000+ | Average: 48.96",
    },
    {
      id: 3,
      name: "KL Rahul",
      role: "Batsman/Wicketkeeper",
      stats: "Runs: 4500+ | Average: 45.67",
    },
    {
      id: 4,
      name: "Jasprit Bumrah",
      role: "Bowler",
      stats: "Wickets: 250+ | Average: 24.45",
    },
    {
      id: 5,
      name: "Hardik Pandya",
      role: "All-rounder",
      stats: "Runs: 1500+ | Wickets: 50+",
    },
    {
      id: 6,
      name: "Rishabh Pant",
      role: "Wicketkeeper/Batsman",
      stats: "Runs: 2000+ | Average: 35.60",
    },
    {
      id: 7,
      name: "Shubman Gill",
      role: "Batsman",
      stats: "Runs: 1000+ | Average: 40.25",
    },
    {
      id: 8,
      name: "Mohammad Shami",
      role: "Bowler",
      stats: "Wickets: 180+ | Average: 27.84",
    },
    {
      id: 9,
      name: "Ravindra Jadeja",
      role: "All-rounder",
      stats: "Runs: 2500+ | Wickets: 200+",
    },
    {
      id: 10,
      name: "Bhuvneshwar Kumar",
      role: "Bowler",
      stats: "Wickets: 140+ | Average: 34.22",
    },
  ];

  return (
    <div className=" bg-gradient-to-r from-teal-500 to-blue-600">
      <h1 className="text-5xl text-center pt-10 text-white uppercase"> Top fantasy players</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-6 md:p-12 lg:p-20">
        {players.map((player) => (
          <div
            key={player.id}
            className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center transform hover:scale-105 transition-transform duration-300"
          >
            <h2 className="text-xl font-semibold text-gray-800">
              {player.name}
            </h2>
            <p className="text-md text-gray-600">{player.role}</p>
            <p className="text-sm text-gray-500 mt-2">{player.stats}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveScore;
