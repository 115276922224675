import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Cards = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const cardItems = [
    {
      id: 1,
      title: "Instant Fantasy Leagues",
      description:
        "Join live fantasy cricket leagues with a starting price of Rs 1. Play instantly during matches!",
    },
    {
      id: 2,
      title: "Live Match Integration",
      description:
        "Engage in real-time fantasy cricket leagues while watching live cricket matches with dynamic odds.",
    },
    {
      id: 3,
      title: "Fast Withdrawals",
      description:
        "Withdraw your winnings in just a few clicks, with fast and secure cash-out options.",
    },
    {
      id: 4,
      title: "Exclusive Rewards & Promotions",
      description:
        "Get access to special offers, bonuses, and contests with exclusive rewards for top players.",
    },
    {
      id: 5,
      title: "Customizable Teams",
      description:
        "Create your own fantasy team, select the best players, and compete against others for exciting rewards.",
    },

  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4 w-full">
      {cardItems.map((item) => (
        <a
          key={item.id}
          href="https://wa.link/ggnow"
          target="_blank"
          rel="noopener noreferrer"
          data-aos="fade-up"
          className="rounded-lg w-full md:max-w-[300px] h-auto p-6 backdrop-blur-xl border border-gray-500 bg-white/10 text-white transition-transform transform hover:scale-105 cursor-pointer duration-300"
          style={{ transformOrigin: "center center" }}
        >
          <h2 className="text-lg font-semibold mb-3">{item.title}</h2>
          <p className="text-md text-gray-100">{item.description}</p>
        </a>
      ))}
    </div>
  );
};

export default Cards;
