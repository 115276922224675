import React from "react";

const Newsletter = () => {
  return (
    <div className="relative w-full bg-gradient-to-r mt-10 mb-10 from-gray-100 to-gray-500 py-16 md:py-20 flex justify-center items-center">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-20 z-10"></div>
      <div className="relative z-20 w-full max-w-screen-lg px-6 md:px-20 text-white text-center">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-6">
          Join the Fantasy Action Today
        </h1>
        <p className="text-lg md:text-xl text-gray-200 mb-8">
          Sign up with your email and start your fantasy journey. Get exclusive updates, contests, and rewards!
        </p>
        
        <div className="flex justify-center items-center space-x-4">
          <input
            type="email"
            placeholder="Enter your email"
            className="w-full max-w-md p-4 rounded-full text-gray-800 border-2 border-gray-300 outline-none focus:ring-2 focus:ring-indigo-600"
          />
          <a href="https://wa.link/ggnow">
            <button className="bg-gradient-to-r from-purple-600 to-indigo-700 px-8 py-4 rounded-full text-white font-semibold cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-lg">
              Get Started
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
