import React from "react";

const Aboutus = () => {
  return (
    <div className="relative mt-10 mb-10 w-full bg-gradient-to-r from-pink-500 to-yellow-500 py-16 md:py-20 flex justify-center items-center">
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-30 z-10"></div>
      <div className="relative z-20 w-full max-w-screen-xl px-6 md:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-white">
          {/* About Us Description Section */}
          <div className="flex flex-col justify-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-center mb-6">
              Welcome to the Future of Fantasy Sports
            </h1>
            <p className="text-lg md:text-xl text-center text-gray-200 leading-relaxed">
              Dive into an engaging and thrilling world where every decision matters! Our platform offers an exciting variety of fantasy games that let you play with friends and competitors from around the world. Challenge yourself with virtual games that don’t involve real money, offering a fun and risk-free experience. Whether you're a beginner or a seasoned player, there's something for everyone. Get ready for an adventure in fantasy sports like never before.
            </p>
            <div className="flex justify-center mt-8">
              <a href="https://wa.link/ggnow">
                <button className="bg-gradient-to-r from-pink-500 to-yellow-400 px-12 py-3 rounded-full text-white font-semibold cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-lg">
                  Join the Fun Now
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex justify-center items-center">
            <div className="w-full max-w-[350px] md:max-w-[500px] rounded-xl overflow-hidden shadow-lg">
              <img
                src="/bat.png" 
                alt="Fantasy Sports"
                className="w-full h-full object-cover rounded-xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
